import { Resolution } from '@blockscout/stats-types';
import type { StatsIntervalIds } from 'types/client/stats';

export const STATS_RESOLUTIONS: Array<{id: Resolution; title: string }> = [
  {
    id: Resolution.DAY,
    title: '天',
  },
  {
    id: Resolution.WEEK,
    title: '周',
  },
  {
    id: Resolution.MONTH,
    title: '月',
  },
  {
    id: Resolution.YEAR,
    title: '年',
  },
];

export const STATS_INTERVALS: { [key in StatsIntervalIds]: { title: string; shortTitle: string; start?: Date } } = {
  all: {
    title: '所有时间',
    shortTitle: '所有',
  },
  oneMonth: {
    title: '一个月',
    shortTitle: '1月',
    start: getStartDateInPast(1),
  },
  threeMonths: {
    title: '三个月',
    shortTitle: '3月',
    start: getStartDateInPast(3),
  },
  sixMonths: {
    title: '六个月',
    shortTitle: '6月',
    start: getStartDateInPast(6),
  },
  oneYear: {
    title: '一年',
    shortTitle: '1年',
    start: getStartDateInPast(12),
  },
};

function getStartDateInPast(months: number): Date {
  const date = new Date();
  date.setMonth(date.getMonth() - months);
  return date;
}
